.banner{
    width: 100%;
    height: 135px;
    background: linear-gradient(to right, #000000, #d4145a);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    @media (max-width: 592px) {
        height: 155px;
    }
    @media (max-width: 440px) {
        height: 185px;
    }
}