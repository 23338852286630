@import "../components_style/utils";

#termOfSell_text{
  text-decoration: underline;
}

.app-container {
  padding: 10px;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  margin-top: 25px;

  @media (min-width: 1008px) {
    max-width: calc(100% - 20px);
  }

  .description {
    margin-bottom: 20px;
  }

  .submit-btn {
    background-color: black;
    color: white;
    margin-top: 20px;
  }
}

.main {
  display: flex;
  @include blocInitialiser(100%, 100%, none);

  @media (max-width:735px) {
    flex-direction: column;
    align-items: center;
  }

  &_inputForm {
    @include blocInitialiser(100%, 50%, none);
    margin-right: 15px;
    padding-right: 15px;

    @media (max-width:735px) {
      margin: 0px;
      padding: 0px;
      width: 100%;
    }
  }

  &_itemList {
    @include blocInitialiser(100%, 50%, none);
    &_itemSlide{
      @include blocInitialiser(430px, 100%, none);
      overflow-y: scroll;
    }

    @media (max-width:735px) {
      margin-top: 15px;
      width: 100%;
    }
  }
}