.roomList_containerWrapper {
    height: 100%;
    width: calc(100% - 20px);
    @media (min-width :1008px) { /// SCREEN RESPONSIVE ------------ ///////////////
        display: flex;       
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

}

.roomList_roomContainer {
    height: 130px;
    border-radius: 10px;
    width: 100%;
    margin-top: 15px;
    background-color: #fff;
    border: 1px solid #DBDBDB;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width :1008px) { /// SCREEN RESPONSIVE ------------ ///////////////
        width: 1008px;       
    }

    @media (min-width:868px) {
        height: 150px;
    }

    &_elmntCenter {
        margin-left: 10px;
        margin-right: 10px;
        height: calc(100% - 20px);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width:512px) {
            justify-content: left;
        }

        &_imgContainer {
            height: 100%;
            width: 40%;
            @media (min-width:512px) {
                width: 160px;
            }
            @media (min-width:868px) {
                width: 210px;
            }
            &_img {
                height: 100%;
                object-fit: cover;
                width: 100%;
                border-radius: 10px;
            }
        }

        &_reservation {
            height: 100%;
            margin-left: 10px;
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media (min-width:646px) {
                width: 250px;
            }
            @media (min-width:868px) {
                width: 300px;
            }
            .roomtitle_roomlist {
                padding: 0px;
                margin: 0px;
                font-size: 17px;
                height: auto;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                line-clamp: 2;
                -webkit-line-clamp: 2;
                /* Limite à 2 lignes */
                overflow: hidden;
            }

            &_price {
                height: auto;
                display: flex;
                align-items: center;
                width: 100%;
                padding-bottom: 3px;
            }

            &_calltoActionBtn {
                display: flex;
                align-items: center;
            }
        }

        &_advantageContainer{
            visibility: hidden;
            display: none;
            @media (min-width:646px) {
                height: 100%;
                width: calc(100% - 510px);
                visibility: visible;
                margin-left: 10px;
                overflow: hidden;
                display: block;
            }
            &_topSetting{
                display: flex;
                flex-wrap: wrap;
            }
            &_elmnt{
                height: 23px;
                width: fit-content;
                padding: 0px 3px 0px 3px;
                padding-bottom: 5px;
                margin: 0px 5px 5px 0px;
                border-radius: 7px;
                border: 1px solid rgb(196, 196, 196);
                background-color: #fff;
            }
        }
    }
}

.roomList_roomContainer_screenWrapper {
    height: 100%;
    width: 100%;
    top: 0px;
    //background-color: #ffffff;
    background-color: #51515190;
    position: fixed;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    @media (min-width:568px) {
        //justify-content: center;
    }
}

.roomList_roomContainer_popupRoom {
    height: auto;
    width: calc(100% - 20px);
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 10px;
    background-color: white;
    @media (min-width:568px) { // tablettes et petit laptops
        background-color: white;
        justify-content: center;
        width: calc(60%);
        padding: 15px;
    }
    @media (min-width:868px) {
        width: 535px;
    }
}

.buttonContainer {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: right;
}

.imageSliderWrapper {
    margin-top: 15px;
}

.roomList_popupRoom_titleWrapper {
    margin-top: 15px;
}

.roomList_popupRoom_maxPersonne {
    height: auto;
    width: 100%;
    display: flex;
}

.roomList_popupRoom_descriptionContainer {
    margin-top: 15px;
}

.roomList_popupRoom_numberOfPersonContainer {
    height: auto;
    margin-top: 15px;
    width: 100%;
    display: flex;
    align-items: center;
}

.roomList_popupRoom_priceAndBtnBlock {
    margin-top: 15px;
    display: flex;
    align-items: center;

    &_btnWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.roomList_popupRoom_priceAndBtnBlock_priceContainer {
    display: flex;
    align-items: center;
    width: fit-content;
    height: auto;
    flex-direction: column;

    &_price {
        font-size: 22px;
        font-weight: bold;
    }

    &_text {
        text-align: center;
        font-size: 13px;
        height: auto;
    }
}