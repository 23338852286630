
.container3 {
    width: 100%;
    height: 750px;
    margin-top: 40px;
    margin-bottom: 10px;

}


.container3-1 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.bloc3-1 {
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10%;

}

.titreC3-1 {
    font-size: 1.5em;
    font-weight: bold;
    font-family: "DM Serif Display", sans-serif;
}

.descriptionC3-1 {
    font-size: 1.5rem;
    font-family: "DM Serif Display", sans-serif;
}


.bloc3-2 {
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.blocImage3-1 {
    width: 45%;
    height: 80%;
}

#image{
    height: 100%;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.imageBS1 {
    width: 100%;
    height: 100%;
    background-color: "#333";
    background-size: cover;
    border-radius: 3%;
    display: flex;
    flex-direction: column;
    background-color: #6363635b;
    position: relative;
}

.blocInfoImg {
    width: 100%;
    height: 20%;
    position: absolute;
}

.infoImg {
    margin-top: 10px;
    margin-left: 10px;
    width: fit-content;
    height: 40px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #d4145a;
}

.imgTexte {
    color: white;
    margin: auto;
    font-size: 1.2rem;
}

.blocInformation {
    width: 40%;
    height: 80%;
    display: flex;
    justify-content: center;
    justify-content: space-between;
    flex-direction: column;
}

.blocTitreInfo {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
}

.titreInfo {
    font-size: 1.7rem;
    font-family: "DM Serif Display", sans-serif;
}

.blocInfoDesc {
    width: 100%;
    height: 45%;
    display: flex;
    align-items: center;
}

.descriptionInfo {
    font-size: 1.1rem;
}

.blocEtoile {
    width: 100%;
    height: 5%;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.etoileInfo {
    width: 25px;
    height: 25px;
    margin-left: 3%;
    background-image: url('../assets/logo/etoile.svg');
    background-size: cover;
}

.blocButtonPrix {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
}

.boutonInfo {

    width: 140px;
    height: 50px;
    background-color: white;
    color: black;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
    transition: color 0.5s, background-color 0.5s;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    font-family: "DM Serif Display", sans-serif;
    font-size: 0.8rem;

}

.boutonInfo:hover {
    background-color: black;
    color: white;

}

.prixInfo {
    font-size: 1.7rem;
    color: black;
    margin-left: 5%;
}

.blocEtoileAndButtonPrixContainer{
    display: flex;
    flex-direction: column;
    height: 100px;
    width: 100%;
    justify-content: space-around;
    background-color: #fff;
    @media (max-width: 600px) {
        height: auto;
        margin-bottom: 29px;
    }
}

/* ----------------------------------------------------
            RESPONSIVE CONTAINER 3 - 600px et 1000px
---------------------------------------------------*/


@media all and (max-width: 800px) {


    .titreC3-1 {
        font-size: 1.5rem;
    }

    .descriptionC3-1 {
        font-size: 1rem;
    }

    .bloc3-2 {
        flex-direction: column;
    }

    .blocImage3-1 {
        width: 95%;
        height: 50%;
    }

    .blocInformation {
        width: 90%;
        height: 80%;
    }

    .titreInfo {
        font-size: 1.2rem;
    }

    .descriptionInfo {
        font-size: 0.9rem;
    }

    .blocInfoDesc {
        height: 55%;
    }

    .blocEtoile {
        height: 10%;
    }

    .blocButtonPrix {
        height: 15%;
    }

    .infoImg{
        padding-left: 5px;

    }

}

@media (orientation:Landscape) and (max-width: 1100px) {


    .titreC3-1 {
        font-size: 1.5em;
    }

    .descriptionC3-1 {
        font-size: 1rem;
    }

    .blocImage3-1 {
        width: 50%;
    }

    .imgTexte {
        font-size: 0.8rem;
    }

    .infoImg {
        width: 55%;
        height: 70%;
    }

    .blocInformation {
        width: 30%;
        height: 80%;

    }

    .titreInfo {
        font-size: 1.2rem;
    }

    .descriptionInfo {
        font-size: 1rem;
    }

    .blocInfoDesc {
        height: 55%;
    }

    .blocEtoile {
        height: 10%;
    }

    .blocButtonPrix {
        height: 15%;
    }

    .prixInfo {
        font-size: 1.5rem;
    }

}


@media (max-width: 800px) {
    .infoImg{
        width: 300px;
    }

    .titreC3-1 {
        font-size: 1.5rem;
    }

    .descriptionC3-1 {
        font-size: 1rem;
    }

    .bloc3-2 {
        flex-direction: column;
        height: 800px;
    }

    .blocImage3-1 {
        width: 90%;
        height: 55%;
    }

    .blocInformation {
        width: 90%;
        height: 30%;
    }

    .titreInfo {
        font-size: 1.3em;
    }

    .descriptionInfo {
        font-size: 0.7rem;
    }

    .blocInfoDesc {
        height: 55%;
    }

    .blocEtoile {
        height: 10%;
    }

    .blocButtonPrix {
        height: 15%;
    }

    .boutonInfo {

        width: 100px;
        height: 35px;
    }

}

@media (max-width: 600px) {


    .titreC3-1 {
        font-size: 1.5rem;
        //background-color: #ff69bc;
    }

    .descriptionC3-1 {
        font-size: 1rem;
        //background-color: #7869ff;

    }

    .bloc3-2 {
        flex-direction: column;
        //background-color: #a3ff69;

    }

    .imgTexte{
        font-size: 18px;
    }

    .blocImage3-1 {
        //background-color: #cb69ff;

        width: 90%;
        height: 50%;
    }

    .blocInformation {
        width: 90%;
        margin-top: 5px;
        margin-top: 5px; 
        height: auto;
        //background-color: #e9ff69;

    }

    .infoImg {

        width: 80%;
        height: 70%;
        //background-color: #69ff7b;

    }

    .titreInfo {
        font-size: 1.2rem;
        //background-color: #307a27;

    }

    .descriptionInfo {
        font-size: 0.9rem;
        //background-color: #87328d;

    }

    .blocInfoDesc {
        //background-color: #942d55;
        height: auto;
    }

    .blocEtoile {
        height: 10%;
        //background-color: #e1ff69;
        display: none;
    }

    .blocButtonPrix {
        height: auto;
        //background-color: #7869ff;
    }

}

//--------------------------------
//--------------------------------
//        Avantages
//--------------------------------
//--------------------------------

.container4 {
    height: auto;
    margin-bottom: 60px;
    margin-top: 60px;
}


.container4-1 {

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

}

.bloc4-1 {
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10%;
}

.titreC4-1 {
    font-size: 3rem;
    font-weight: bold;
}

.descriptionC4-1 {
    font-size: 1.5rem;
}

.bloc4-2 {
    width: 100%;
    height: 100%;
}

.bloc4-3 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.bloc4-4 {
    width: 20%;
    height: 90%;
}

.bloc4-5 {
    width: 100%;
    height: 15%;
}

.bloc4-5Titre {
    font-size: 1.2rem;
    font-weight: bold;
    padding-top: 5%;
}

.bloc4-6 {
    width: 100%;
    height: 35%;
    padding-top: 25%;
}

.bloc4-6Image1 {
    width: 120px;
    height: 120px;
    font-size: 2rem;
    background-image: url('../assets/icons/choices.svg');
    background-size: cover;
}

.bloc4-6Image2 {
    width: 120px;
    height: 120px;
    font-size: 2rem;
    background-image: url('../assets/icons/service-client.svg');
    background-size: cover;
}

.bloc4-6Image3 {
    width: 120px;
    height: 120px;
    font-size: 2rem;
    background-image: url('../assets/icons/badge.svg');
    background-size: cover;
}

.bloc4-7 {
    width: 100%;
    height: 50%;
}

.bloc4-7Desc {
    font-size: 1rem;
    text-align: justify;
}

/* ----------------------------------------------------
            RESPONSIVE CONTAINER 4 - 600px et 1000px
---------------------------------------------------*/



@media all and (max-width: 600px) {

    .container4 {
        height: auto;
    }


    .container4-1 {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

    }

    .bloc4-1 {
        width: 100%;
        height: 8%;
    }

    .titreC4-1 {
        font-size: 2rem;
        font-weight: bold;
        margin-top: 5%;
        margin-left: 10%;
    }

    .descriptionC4-1 {
        font-size: 1rem;
        margin-left: 10%;
    }

    .bloc4-2 {
        width: 100%;
        height: 100%;
    }


    .bloc4-3 {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .bloc4-4 {
        width: 100%;
        height: 60%;
    }

    .bloc4-5 {
        width: 100%;
        height: 15%;
    }

    .bloc4-5Titre {
        font-size: 1.2em;
        font-weight: bold;
        padding-top: 5%;
        text-align: center;
    }

    .bloc4-6 {
        width: 100%;
        height: 40%;
        padding-top: 5%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .bloc4-7Desc {
        font-size: 1rem;
        text-align: justify;
        padding-left: 5%;
        padding-right: 5%;
    }



}


@media (orientation:Landscape) and (max-width: 800px) {

    .bloc4-4 {
        width: 30%;
        height: 90%;
    }

    .bloc4-5 {
        height: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .bloc4-7Desc {
        font-size: 0.9rem;
        text-align: justify;
        padding-left: 1%;
        padding-right: 1%;
    }

    .bloc4-6 {

        display: none;

    }

    .bloc4-7 {
        height: 70%;
    }

    .bloc4-5Titre {
        font-size: 1rem;
        font-weight: bold;
        padding-top: 2%;
    }

    .titreC4-1 {
        font-size: 1.5rem;
    }

    .descriptionC4-1 {
        font-size: 1.1rem;
    }


}