$SlideShowHeight: 620px;
$PackWidth: 300px;
$PackHeight: 400px;

.SlideShow {
    margin-top: 20px;
    height: $SlideShowHeight;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__travelContainer {
        overflow: hidden;
        width: calc(100% - 40px);
    }

    &__packs {
        display: flex;
        justify-content: space-around;
        display: flex;
        transition: transform 0.3s ease-in-out;
        margin-top: 20px;
    }

    &__buttonContainer {
        height: 50px;
        width: 300px;
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
}