@mixin textLine ($line) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: $line;
    -webkit-line-clamp: $line;
    /* Limite à 2 lignes */
    overflow: hidden;
}


.travelInformation {
    height: auto;
    width: 100%;
    padding: 10px;

    @media (min-width :1008px) {
        /// SCREEN RESPONSIVE ------------ ///////////////
        width: 1008px;
    }

    &_bigScreenResp {
        @media (min-width:766px) {
            display: flex;
            width: 100%px;
        }

        &_titleAndAdventage {

            @media (min-width:766px) {
                width: 370px;
                overflow: hidden;
                margin-left: 10px;
            }
        }
    }

    &_titleWrapper {
        margin-top: 10px;
        font-size: 15px;
        height: auto;
        width: 100%;
        @include textLine(2);
    }

    &_description {
        height: auto;
        margin-top: 10px;

        &_textBasic {
            margin-top: 5px;
            @include textLine(5);
            width: 100%;
            height: auto;

        }

        &_textExpand {
            margin-top: 5px;
            width: 100%;
            height: auto;
        }

    }

    &_details {
        margin-top: 10px;

        &_location {
            margin-top: 10px;
            display: flex;
            align-items: center;

            &_iconContainer {
                height: 25px;
                width: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;

                &_img {
                    width: 90%;
                    height: 100%;
                }
            }
        }

        &_dateContainer {
            margin-top: 10px;
            height: auto;
            width: 230px;
            display: flex;
            justify-content: space-between;

            &_date {
                font-size: 18px;
            }
        }
    }
}