@mixin textLine ($line) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: $line;
    -webkit-line-clamp: $line;
    /* Limite à 2 lignes */
    overflow: hidden;
}

.connexionPage {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
}


.title_headSection {
    width: 100%;
    height: auto;
    padding: 10px 10px 10px 10px;

    @media (min-width :1008px) {
        /// SCREEN RESPONSIVE ------------ ///////////////
        width: 1008px;
    }
}

/*.exclusiveSection{
    height: auto;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
        @media (min-width :1008px) {
        /// SCREEN RESPONSIVE ------------ ///////////////
        width: 1008px;
    }
    &_cartAdventageContainer{
        height: auto;
        width: 100%;
        display: flex;
        margin-top: 10px;
        &_adventages{
            height: auto;
            width: 100%;
            padding-left: 28px;
            &_list{
                list-style: circle;
                font-weight: bold;
                li{
                    margin-bottom: 5px;
                }
            }
        }
    }
}*/

.roomList,
.suppList {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #EFEFEF;
    padding-bottom: 15px;
}

.suppList {
    background-color: #EFEFEF;
    padding-bottom: 15px;
}

.addCarteCadeauSection {
    height: auto;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;

    @media (min-width :1008px) {
        /// SCREEN RESPONSIVE ------------ ///////////////
        width: 1008px;
    }

    &_priceContainer {
        height: 80px;
        width: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &_price {
            font-size: 29px;
            width: fit-content;
            height: fit-content;
            color: green;
            font-weight: bold;
        }

        &_priceDescription {
            text-align: center;
            font-size: 12px;
        }
    }

    &_btnWrapper {
        height: 45px;
        width: calc(100% - 90px);
        margin-left: 10px;
        background-color: #000000;
        color: white;
        border-radius: 6px;
        text-align: center;
    }
}

.btnContainerEndOfPage {
    width: 100%;
    height: fit-content;
    padding: 0px 10px 10px 10px;

    @media (min-width :1008px) {
        /// SCREEN RESPONSIVE ------------ ///////////////
        width: 1008px;
    }
}