
.container1 {
    width: 100%;
    height: 100vh;
    background-image: url('../assets/background/image-de-fond-de-homePage-belle-ville-voyage-camping-luxembourg-visiter.webp');
    background-size: cover;
    background-position: center;
}


.blocHaut1-1 {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    z-index: 2;
    align-self: center;
}

.blocTexte1-1 {
    width: 60%;
    height: 45%;
}

.titreC1-1 {
    width: 100%;
    height: 30%;
    color: white;
    display: flex;
    align-items: center;
}

#mainTitle{
    font-size: 34px;
}

@media (max-width:522px) {
    #mainTitle{
        font-size: 27px;
    }
}

.presentationC1-1 {
    width: 100%;
    height: 50%;
    font-size: 1.2rem;
    color: white;
    text-align: justify;
    padding: 1%;
}

.blocBouton1-1 {
    width: 300px;
    height: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 384px) {
        margin-top: 55px;
    }
}

.blocReseau1-1 {
    width: 5%;
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
}


.imageFB,
.imageInstagram {
    font-size: 3.5rem;
    color: white;
    cursor: pointer;
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageFB:hover,
.imageInstagram:hover {
    color: rgb(165, 165, 165);
}
.blocBas1-1 {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
}

.blocSwap1-1 {
    display: none;
}

.blocReseauBas1-1 {
    display: none;
}


/*RESPONSIVE */

@media all and (max-width: 1500px) {
    .titreC1-1 {
        font-size: 2.5rem;
    }
}

@media all and (max-width: 1400px) {


    .blocTexte1-1 {
        width: 75%;
        height: 65%;
    }

    .titreC1-1 {
        font-size: 2.2rem;
    }

    .presentationC1-1 {
        font-size: 1rem;
    }


}



@media all and (max-width: 600px) {


    .blocTexte1-1 {
        width: 95%;
        height: 75%;
    }

    .titreC1-1 {
        font-size: 1.5rem;
    }


    .presentationC1-1 {
        font-size: 0.9rem;
    }

    .blocBouton1-1 {
        width: 100%;
        height: 20%;

    }

    .blocReseau1-1 {
        display: none;
    }

    .blocSwap1-1 {
        width: 65%;
        height: 90%;
        display: block;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: row;
        color: white;
    }

    .blocReseauBas1-1 {
        display: block;
        width: 15%;
        height: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }



    .boutonSwap {
        font-size: 5rem;
        margin-left: 2%;
        margin-right: 2%;
    }

    .imageFB,
    .imageInstagram {
        font-size: 2rem;

    }



}


@media (orientation: landscape) and (max-width: 800px) {


    .blocHaut1-1 {
        height: 100%;
    }

    .blocBas1-1 {
        display: none;
    }

    .blocTexte1-1 {
        width: 75%;
        height: 80%;
    }

    .titreC1-1 {
        font-size: 1.2rem;
    }

    .presentationC1-1 {
        font-size: 0.9rem;
    }

    .blocBouton1-1 {
        width: 100%;
        height: 20%;

    }

    .reserver {
        width: 120px;
        height: 40px;
    }

    .explorer {
        width: 120px;
        height: 40px;

    }

}