
body,
html {
  width: 100%;
  list-style: none;
}

.logo-ban{
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-ban__logo{
  width: 200px;
  margin-top: 5px;
}

#ham-menu {
  display: none;
}
label[for="ham-menu"] {
  display: block;
  position: fixed;
  top: 24px;
  left: 20px;
  z-index: 999;
  width: 60px;
  height: 60px;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  -webkit-box-shadow: 8px 7px 12px -7px rgba(0,0,0,0.28); 
  box-shadow: 8px 7px 12px -7px rgba(0,0,0,0.28);
}
.ham-menu {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  visibility: hidden;
  transform: translate(-110%);
  z-index: 998;
  background-color: white;
  transition: 1s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ham-menu > ul {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  padding: 20px;
  height: 50%;
}
.ham-menu > ul > li {
  font-size: 5rem;
  white-space: nowrap;
  letter-spacing: 0.15em;
  cursor: pointer;
  color: rgb(0, 0, 0);
}
#ham-menu:checked + label {
  background-color: transparent;
  border-color: rgb(0, 0, 0);
}
#ham-menu:checked ~ div.ham-menu {
  transform: translate(0px);
  visibility: visible;
}
.full-page-green {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(252, 252, 252);
  z-index: 997;
  opacity: 0;
  visibility: hidden;
  display: none;
  transition: 500ms;
  position: fixed;
  top: 0;
  left: 0;
}
#ham-menu:checked ~ div.full-page-green {
  display: block;
  opacity: 1;
  visibility: visible;
}
[for="ham-menu"] > div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  align-items: center;
}
.menu-line {
  display: block;
  width: 17px;
  height: 2px;
  margin: 10px 0 5px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  background-color: black;
  transition: 500ms;
  transform-origin: right center;
}
[for="ham-menu"] > div > span:nth-child(4),
[for="ham-menu"] > div > span:nth-child(5),
[for="ham-menu"] > div > span:nth-child(6) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  transform-origin: left center;
}
#ham-menu:checked + label span {
  background-color: black;
}
#ham-menu:checked + label span:nth-child(2),
#ham-menu:checked + label span:nth-child(5) {
  transform: scale(0);
}
#ham-menu:checked + label span:nth-child(1) {
  transform: translateY(17px) rotate(45deg);
}
#ham-menu:checked + label span:nth-child(4) {
  transform: translateY(17px) rotate(-45deg);
}
#ham-menu:checked + label span:nth-child(3) {
  transform: translateY(-17px) rotate(-45deg);
}
#ham-menu:checked + label span:nth-child(6) {
  transform: translateY(-17px) rotate(45deg);
}


.centre-text a{
  text-align: center;
  text-decoration: none;
}

.bold-text {
  font-weight: bolder;
  list-style: none;
  font-size: 5.5vh;
  font-family: 'DM Serif Display', serif;
}
