@import '../components_style/_utils.scss';

@mixin textLine ($line) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: $line;
    -webkit-line-clamp: $line;
    /* Limite à 2 lignes */
    overflow: hidden;
}

.sectionBoxDeVoyage__box {
    @include blocInitialiser(auto, 320px, none);
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;

    position: relative;

    //overflow: hidden;
    &__badge {
        position: absolute;
        top: -13px;
        right: -10px;
        z-index: 2;
        padding: 5px 10px;
        background: linear-gradient(to right, #000000, #d4145a);
        color: white;
        font-size: 12px;
        font-weight: bold;
        border-radius: 20px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        text-align: center;
        white-space: nowrap;
    }

    @media (max-width: 1034px) {
        @include blocInitialiser(auto, 270px, none);
    }

    &__header {
        @include blocInitialiser(30px, 100%, none);
        display: flex;
        justify-content: space-between;
        @include textLine(1);

        &__title {
            @include blocInitialiser(100%, auto, none);
            @include textLine(1);
            font-size: 0.83rem;

        }

        &__add {
            @include blocInitialiser(50px, 50px, none);
        }
    }

    &__stars {
        @include blocInitialiser(20px, none, none);

        &__img {
            height: 20px;
        }
    }

    &__img {
        margin-top: 10px;
        @include blocInitialiser(130px, 100%, rgb(231, 231, 231));
        margin-bottom: 10px;
        border-radius: 10px;

        &__img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 10px;
            overflow: hidden;
        }

        @media (max-width: 1034px) {
            height: 110px;
            margin-bottom: 5px;
            margin-top: 5px;
        }
    }

    &__location {
        @include blocInitialiser(30px, 100%, none);
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        @media (max-width: 1034px) {
            margin-bottom: 5px;
        }

        &__icon {
            height: 20px;
            margin-right: 5px;
        }

        &__text {
            height: 25px;
            width: 100%;
            @include textLine(1);
        }
    }

    &__description {
        @include blocInitialiser(object-fit, 100%, none);
        @include textLine(5);
        margin-bottom: 5px;
    }

    &__bottom {
        @include blocInitialiser(55px, 100%, none);
        @include flexStyle;

        &__price {
            @include blocInitialiser(55px, 140px, none);
            font-size: 14px;
            @include flexStyle(center, center);
            flex-direction: column;
            margin-left: 15px;
        }
    }
}