@import '../components_style/_utils.scss';

.container7 {
    height: 30vh;
}

.container7-1 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #d1d1d1;
}

.BackgroundPack {
    width: 100%;
    height: 100%;
    background-image: url('https://images.pexels.com/photos/1579253/pexels-photo-1579253.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.backgroundDarken{
    padding: 20px;
    height: 100%;
    width: 100%;
    background-color: #13131376;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.TitrePack {
    font-size: 1.35em;
    font-weight: bold;
    color: white;
    //background-color: #c2ff8d;
}

.DescriptionPack {
    font-size: 1rem;
    color: white;
    text-align: center;
    //background-color: #e48dff;
}


@media (max-width: 1100px) {
    .TitrePack {
        font-size: 1rem;
    }

    .DescriptionPack {
        font-size: 0.7rem;
    }
}

@media (max-width: 693px) {
    .PackEnglobe {
        width: 90%;
        height: 250px;
    }
}

//-----------------------
//-----------------------
//     Box de voyage 
//-----------------------
//-----------------------

.sectionBoxDeVoyage{
    @include blocInitialiser(auto,100%,none);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 150px;
    margin-top: 70px;
    &__PageViewContainer{
        height: auto;
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-left: 20px;
        margin-right: 20px;
    }
    
}
